<template>
  <div v-if="isLoading">
    <div class="text-center text-danger custom-screen-center carga">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else class="row mb-2">
    <div class="col-sm-12">
      <div class="card text-left">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <div v-if="reportes.length > 0 || efectivos.length > 0">
                <img src="@/assets/images/e32.png"
                  @click="reporteExcel"
                  style="cursor: pointer"
                  alt="excel"
                  title="formato excel"
                >
                <img src="@/assets/images/pdf32.png"
                  @click="reportePDF"
                  style="cursor: pointer"
                  alt="pdf"
                  title="formato pdf"
                >
              </div>
            </div>
            <div class="col-sm-8">
              <div
                class="col-sm-12 text-center font-weight-bold"
                style="font-size: 1.626rem;height: 34px"
              >
                VENTA DEL DIA "Medico Terceros"
              </div>
              <div class="col-sm-12 text-center">
                Expresado en {{itemMoneda.name}}
              </div>
            </div>
            <div class="col-2">
              <div class="justify-content-center">
                <img :src="getPathLogo" alt="Imagen Empresa" height="80px">
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-2 text-right font-weight-bold">Fecha:</div>
            <div class="col-sm-4">{{formatDate}}</div>
          </div>
          <div class="form-group row">
            <div class="col-sm-2 text-right font-weight-bold">Sucursal:</div>
            <div class="col-sm-4">{{nombreSucursal}}</div>
            <div class="col-sm-2 text-right font-weight-bold">Usuario:</div>
            <div class="col-sm-4">{{nombreUsuario}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <div class="table-responsive">
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th :colspan="8" class="colspan-white"></th>
                      <th class="th-custom-color" :colspan="3">Forma de Pago</th>
                      <th :colspan="1" class="colspan-white"></th>
                    </tr>
                    <tr>
                      <th class="th-custom-color" width="2%">N°</th>
                      <th class="th-custom-color" width="6%">Origen</th>
                      <th class="th-custom-color" width="3%">Estado</th>
                      <th class="th-custom-color" width="5%">N° F.</th>
                      <th class="th-custom-color" width="10%">Razon Social</th>
                      <th class="th-custom-color" width="5%">Nit</th>
                      <th class="th-custom-color" width="3%">Importe</th>
                      <th class="th-custom-color" width="3%">Desc</th>
                      <th class="th-custom-color" width="3%">Caja</th>
                      <th class="th-custom-color" width="3%">Banco</th>
                      <th class="th-custom-color" width="3%">Tarjeta</th>
                      <th class="th-custom-color" width="10%">Medico</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(reporte, index) in reportes" :key="index"
                      :class="reporte.estado === 'Anulada'? 'bg-anulado':''"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ reporte.origen }}</td>
                      <td>{{ reporte.estado }}</td>
                      <td>{{ reporte.numero }}</td>
                      <td>{{ reporte.nombre }}</td>
                      <td>{{ reporte.nit }}</td>
                      <td v-if="reporte.total !== 0 && reporte.total !== null">
                        {{ reporte.total | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.descuento_monto !== 0 && reporte.descuento_monto !== null">
                        {{ reporte.descuento_monto | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.caja !== 0 && reporte.caja !== null">
                        {{ reporte.caja | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.banco !== 0 && reporte.banco !== null">
                        {{ reporte.banco | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.tarjeta !== 0 && reporte.tarjeta !== null">
                        {{ reporte.tarjeta | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td>{{ reporte.medico }}</td>
                    </tr>
                    <tr>
                      <th colspan="6" class="text-right colspan-white">Total:</th>
                      <th class="th-custom-color">
                        {{ totalImporte | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color">
                        {{ totalDescuento | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color">
                        {{ totalCaja | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color">
                        {{ totalBanco | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color">
                        {{ totalTarjeta | numeral('0,0.00') }}
                      </th>
                      <th colspan="1" class="text-right colspan-white"></th>
                    </tr>
                    <tr>
                      <th colspan="8" class="text-right colspan-white">Total Efectivo:</th>
                      <th colspan="3" class="th-custom-color">
                        {{ totalEfectivo | numeral('0,0.00') }}
                      </th>
                      <th colspan="1" class="text-right colspan-white"></th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-sm-7">
              <table class="table table-bordered text-center">
                <thead>
                  <tr>
                    <th colspan="2" class="th-custom-color">Medico</th>
                    <th class="th-custom-color">Ingreso del Día</th>
                    <!-- <th class="th-custom-color">Saldo Anterior</th> -->
                    <th class="th-custom-color">Pagado</th>
                    <!-- <th class="th-custom-color">Saldo</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(efectivo, index) in efectivos" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ efectivo.medico }}</td>
                    <td>{{ efectivo.total | numeral('0,0.00') }} {{ itemMoneda.simbolo }}</td>
                    <!-- <td>
                      {{ efectivo.saldo_anterior | numeral('0,0.00') }} {{ itemMoneda.simbolo }}
                    </td> -->
                    <td>{{ efectivo.pago | numeral('0,0.00') }} {{ itemMoneda.simbolo }}</td>
                    <!-- <td>{{ efectivo.saldo | numeral('0,0.00') }} {{ itemMoneda.simbolo }}</td> -->
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th colspan="2" class="text-right">Total</th>
                    <th class="th-custom-color">
                      {{ totalResumenEfectivo | numeral('0,0.00') }} {{ itemMoneda.simbolo }}
                    </th>
                    <!-- <th class="th-custom-color">
                      {{ saldoAnteriorResumenEfectivo | numeral('0,0.00') }}
                      {{ itemMoneda.simbolo }}
                    </th> -->
                    <th class="th-custom-color">
                      {{ pagoResumenEfectivo | numeral('0,0.00') }} {{ itemMoneda.simbolo }}
                    </th>
                    <!-- <th class="th-custom-color">
                      {{ saldoResumenEfectivo | numeral('0,0.00') }} {{ itemMoneda.simbolo }}
                    </th> -->
                  </tr>
                  <tr>
                    <th colspan="2" class="text-right">Saldo Total</th>
                    <th class="th-custom-color">
                      {{ totalResumenEfectivo - pagoResumenEfectivo | numeral('0,0.00') }} {{ itemMoneda.simbolo }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12 text-center pt-3">
              <strong>Consulta:</strong> {{fechaConsulta}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ReporteMedicoTerceros',
  data() {
    return {
      isLoading: true,
      hash: this.$route.params.hash,
      itemMoneda: '',
      nombreSucursal: '',
      nombreUsuario: '',
      reportes: [],
      efectivos: [],
      fechaConsulta: '',
    };
  },
  created() {
    this.cargarReporteApi();
  },
  methods: {
    decodeHash() {
      return JSON.parse(atob(this.hash));
    },
    async cargarReporteApi() {
      try {
        const REQUEST = {
          fecha: this.decodeHash().fecha,
          sucursal_id: this.decodeHash().sid,
          usuario_id: this.decodeHash().uid,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/medico_venta_dia/web', {
          params: {
            data: JSON.stringify(REQUEST),
          },
        });
        this.itemMoneda = RESPONSE.data.data.moneda;
        this.nombreSucursal = RESPONSE.data.data.sucursal.nombre;
        this.nombreUsuario = RESPONSE.data.data.usuario.nombre;
        this.reportes = RESPONSE.data.data.reporte.detalles;
        this.efectivos = RESPONSE.data.data.reporte.efectivos;
        this.fechaConsulta = RESPONSE.data.data.fecha_consulta;
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async reporteExcel() {
      try {
        this.isLoading = true;
        const REQUEST = {
          fecha: this.decodeHash().fecha,
          sucursal_id: this.decodeHash().sid,
          usuario_id: this.decodeHash().uid,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/medico_venta_dia/excel', {
          params: {
            data: JSON.stringify(REQUEST),
          },
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'reporteMedicoTerceros.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async reportePDF() {
      try {
        this.isLoading = true;
        const REQUEST = {
          fecha: this.decodeHash().fecha,
          sucursal_id: this.decodeHash().sid,
          usuario_id: this.decodeHash().uid,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/medico_venta_dia/pdf', {
          params: {
            data: JSON.stringify(REQUEST),
          },
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    ...mapGetters('main', ['getPathLogo']),
    formatDate() {
      const FECHA = JSON.parse(atob(this.hash)).fecha;
      const DIA = moment(FECHA, 'YYYY-MM-DD', 'es').format('dddd');
      const FECHA_FORMATEADA = moment(FECHA, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return `${DIA.charAt(0).toUpperCase()}${DIA.slice(1)} ${FECHA_FORMATEADA}`;
    },
    totalImporte() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += parseFloat(reporte.total);
      });
      return total;
    },
    totalDescuento() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += parseFloat(reporte.descuento_monto);
      });
      return total;
    },
    totalCaja() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += (reporte.caja) ? parseFloat(reporte.caja) : 0;
      });
      return total;
    },
    totalBanco() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.banco ? parseFloat(reporte.banco) : 0;
      });
      return total;
    },
    totalTarjeta() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.tarjeta ? parseFloat(reporte.tarjeta) : 0;
      });
      return total;
    },
    totalEfectivo() {
      return this.totalCaja + this.totalBanco + this.totalTarjeta;
    },
    totalResumenEfectivo() {
      let total = 0;
      this.efectivos.forEach((e) => { total += e.total ? parseFloat(e.total) : 0; });
      return total;
    },
    saldoAnteriorResumenEfectivo() {
      let total = 0;
      this.efectivos.forEach((e) => {
        total += e.saldo_anterior ? parseFloat(e.saldo_anterior) : 0;
      });
      return total;
    },
    pagoResumenEfectivo() {
      let total = 0;
      this.efectivos.forEach((e) => { total += e.pago ? parseFloat(e.pago) : 0; });
      return total;
    },
    saldoResumenEfectivo() {
      let total = 0;
      this.efectivos.forEach((e) => { total += e.saldo ? parseFloat(e.saldo) : 0; });
      return total;
    },
  },
};
</script>

<style scoped>
.bg-anulado{
  background-color: rgba(255, 82, 82,0.7);
}
.carga{
  padding-top: 15%;
}
</style>
